export enum ActionType {
  ADD_NEW_TIME_LINE_ITEM,
  RESET_TIME_LINE,
}

export interface Action {
  type: ActionType
  payload: any
}

export const addNewTimeLineItem = (index: number): Action => {
  return {
    type: ActionType.ADD_NEW_TIME_LINE_ITEM,
    payload: index
  }
} 
