import React from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      paddingTop: '4rem'
    },
  })
)

export const Logo = () => {
  const classes = useStyles()
  return (
    <div className='header'>
      <div className={classes.container}>
        <Grid container justify='center'>
          <div style={{width:'100%'}}>
            <img
              src='booster.png'
              loading='lazy'
              alt=''
              style={{height: '60px', width: 'auto'}}
            />
            <span className="presents">
            PRESENTS
            </span> 
          </div>
          <div>
          <p className="extreme">EXTREME PROGRAMMING</p>
          <p className="zombie">ZOMBIE APOCALYPSE EDITION</p>
          </div>
        </Grid>
      </div>
   </div>
  )
}
