import { Grid } from "@material-ui/core";

const questions = [
  [
    "What just happened?",
    "You've just witnessed a person creating a serverless, reliable, and scalable broadcast messaging app while being chased by a motherfucking zombie.",
  ],
  ["A what?", "A zombie."],
  [
    "No, the other thing",
    "A backend with Booster framework, an open-source project to write cloud-native backends, faster than ever. It's not sci-fi! Become a 10x developer with Booster NOW!",
  ],
];
export const FAQ = () => (
  <Grid container>
    <Grid container style={{ width: "100%" }} justify="center">
      <p className="faq-title">FAQ</p>
    </Grid>
    {questions.map(([q, a], index) => (
      <div key={index} style={{ margin: "10px" }}>
        <p className="faq-letter" style={{ color: "#ffc700" }}>
          <b>Q:</b> {q}
        </p>
        <p className="faq-letter">
          <b>A:</b> <span className="faq-response">{a}</span>
        </p>
      </div>
    ))}
  </Grid>
);
