import { faLinkedin, faTwitterSquare } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const SocialLinks = () => {
  return (
    <div className="social-links">
        <a id="share-with-twitter" href="https://twitter.com/share?url=https://extreme.booster.cloud" target="_blank">
          <span><FontAwesomeIcon icon={faTwitterSquare} color="#1b9cf0"/></span>
          <div className="social-link-text">Tweet</div>
        </a>
        <a id="share-with-linkedin" href="https://www.linkedin.com/sharing/share-offsite/?url=https://extreme.booster.cloud" target="_blank">
          <span><FontAwesomeIcon icon={faLinkedin} color="#0e76a8"/></span>
          <div className="social-link-text">Share in LinkedIn</div>
        </a>
    </div>
  );
}