import { Container } from "@material-ui/core";
import React, { Fragment, useEffect, useReducer } from "react";
import { VideoBackground, VideoEmbedded } from "./components/Video";
import Grid from "@material-ui/core/Grid";
import { IntroText } from "./components/IntroText";
import { isLast } from "./data/timeline";
import { Logo } from "./components/Logo";
import { FAQ } from "./components/FAQ";
import { reducer } from "./context/reducer";
import { initialState } from "./context/state";
import { DispatchContext, StateContext } from "./context/context";
import { Form } from "./components/Form";
import { Footer } from "./components/Footer";
import { useBoosterAnalytics } from "./services/analytics";
import { Boom } from "./components/Boom";
import { TimeLineGrid } from "./components/TimeLineGrid";
import "animate.css";
import { CookiesBanner } from "./components/CookiesBanner";
import { SocialLinks } from "./components/SocialLinks";

function ScrollOffsetHack(state: { currentIndex: number }) {
  return isLast(state.currentIndex + 1) ? (
    <Fragment />
  ) : (
    <div style={{ height: "200px" }}></div>
  );
}

function App() {
  useBoosterAnalytics();
  const [state, dispatch] = useReducer(reducer, initialState);
  
  return (
    <DispatchContext.Provider value={dispatch}>
      <StateContext.Provider value={state}>
        <VideoBackground />
        <CookiesBanner />
        <Container fixed>
          <Grid container spacing={3} justify="center">
            <Grid item md={10} xl={12} xs={12}>
              <Logo />
            </Grid>
            <Grid item md={10} xl={12} xs={12}>
              <VideoEmbedded uri={"booster-zombie-polished.mp4"} />
              <SocialLinks />
            </Grid>
            <Grid item md={10} xl={12} xs={12} className="copies">
              <IntroText />
            </Grid>
            <Grid item md={10} xl={12} xs={12}>
              <TimeLineGrid />
            </Grid>
            <Grid item md={10} xl={12} xs={12}>
              {isLast(state.currentIndex + 1) && <Boom />}
            </Grid>
            <Grid item md={10} xl={12} xs={12} className="copies">
              {isLast(state.currentIndex + 1) && <FAQ />}
            </Grid>
            <Grid item md={10} xl={12} xs={12}>
              {isLast(state.currentIndex + 1) && <Form />}
            </Grid>
            <Grid item md={10} xl={12} xs={12}>
              {isLast(state.currentIndex + 1) && <Footer />}
            </Grid>
            <ScrollOffsetHack currentIndex={state.currentIndex} />
          </Grid>
        </Container>
      </StateContext.Provider>
    </DispatchContext.Provider>
  );
}

export default App;
