export type TimeLineItem = {
  time: string;
  description: string;
  title: string;
  snippet: string;
};

export const timeLineItems = [
  {
    time: "0:27",
    title: "Creating a new project",
    description:
      "All Booster projects start with this command. It initializes a TypeScript project with cool stuff for you:",
    snippet: `$ boost new:project broadcast-app
\\
? What's your project description? (default: "")
\\
...more questions...
\\
Project generated!
    `,
  },
  {
    time: "0:29",
    title: "Creating a Command",
    description:
      "Commands are imperative orders we send to a Booster app that will register an event later:",
    snippet: `$ boost new:command BroadcastMessage --fields networkId:UUID message:string
\\
Verifying Project
Creating new command
Command generated!
      `,
  },
  {
    time: "0:30",
    title: "Generating an Event",
    description:
      "Events are the source of truth of the system. They are stored in an infinite event stream that represents how the system has reached its current state.",
    snippet: `$ boost new:event BroadcastPerformed --fields networkId:UUID message:string
\\
Verifying project
Creating new event
Event generated!`,
  },
  {
    time: "0:31",
    title: "Defining an Entity",
    description:
      "Entities are concepts that represent the state of your domain. We generate the entity by reducing the event stream.",
    snippet: `$ boost new:entity CommunicationNetwork --fields "messages:Array<string>" --reduces BroadcastPerformed
\\
Verifying project
Creating new entity
Entity generated!
`,
  },
  {
    time: "0:32",
    title: "Creating a Read Model",
    description:
      "Read models project entities to expose only the data needed in the API:",
    snippet: `$ boost new:read-model CommunicationReadModel --fields "messages:Array<string>" --projects CommunicationNetwork:id
\\
Verifying project
Creating new read model
Read model generated!
`,
  },
  {
    time: "0:33",
    title: "Add your business logic!",
    description:
      "This is the magical part. Forget about infrastructure, glue-code, or low-level zombie stuff. THE ONLY CODE THAT MATTERS IS THE ONE THAT MAKES YOUR APP DIFFERENT:",
    snippet: `// BroadcastMessage.handle function
public static async handle(
  command: BroadcastMessage,
  register: Register
): Promise<void> {
  if(command.message.length > 280) {
    throw new Error(
      'Messages cannot have more than 280 chars!'
    )
  }
  register.events(
    new BroadcastPerformed(
      command.networkId, command.message
    )
  )
}
    `,
  },
  {
    time: "0:35",
    title: "Deploy to production",
    description:
      "Booster will analyze the project and infer all the required infrastructure. Fuck yeah! It will do everything on the cloud so you can interact with the app with no zombies biting your ass!",
    snippet: `$ boost deploy -e production
\\
<a bunch of AWS deployment status messages>
\\
...
broadcast-app.httpURL = <URL>
broadcast-app.websocketURL = <URL>
...
Deployment complete!
`,
  },
  {
    time: "0:42",
    title: "Interacting with the system",
    description:
      "You can use ANY GraphQL client to interact with your backend, which means you don't have to write any damn SDK anymore!",
    snippet: `
mutation {
  BroadcastMessage(input: {
    message: "I need help at 43.82, 22.04"
    networkId: "1337"
  })
}
`,
  },
];

export const isLast = (index: number): boolean => {
  return index >= timeLineItems.length;
};
