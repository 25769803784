import React, { useEffect } from 'react';
import ReactPlayer from 'react-player';

export interface EmbeddedVideoProps {
  uri: string
}

export const VideoBackground = () => {

  return (
    <div>
      <video className='videoBack' autoPlay loop muted>
        <source src={'back.mp4'} type='video/mp4' />
      </video>
      <div className='gradient'></div>
    </div>
  )
}

export const VideoEmbedded = (props: EmbeddedVideoProps) => {
  return (
    <div className='player-wrapper'>
      <ReactPlayer
          className='react-player'
          url={props.uri}
          width='100%'
          height='100%'
          controls = {true}
        />
    </div>
  )
}
