export const Footer = () => {
  return (
    <div className="footer">
      <p style={{ textAlign: "center" }}>
        This experience was created with the open-source project{" "}
      </p>
      <p style={{ textAlign: "center" }}>
        <a href="https://www.booster.cloud/" className="footerLink">
          <img
            src="booster.png"
            loading="lazy"
            alt=""
            className="footer-image"
            style={{ height: "auto", width: "250px" }}
          />
        </a>
      </p>
      <a href="https://www.theagilemonkeys.com/" className="footerLink">
        <p style={{ textAlign: "center" }}>
          An initiative by{" "}
          <span className="monkeyFont">The Agile Monkeys.</span>
        </p>
      </a>
      <a href="https://booster.cloud/privacy-policy" className="footerLink">
        <p style={{ textAlign: "center" }}>
          Privacy policy
        </p>
      </a>

      <p className="footerDisclaimer">
        Background video by 3D COR and Savvas Karampalasis
      </p>
    </div>
  );
};
