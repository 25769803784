import '../styles/crt.css'
import { WindupChildren } from 'windups'
import { TimeLineItem } from '../data/timeline'

export const Terminal = (props:  { item: TimeLineItem }) => {
  const text = props.item.snippet as string
  return (
    <div className='container-crt'>
      <div className='scanline'></div>
        <div className='crt'>
          <WindupChildren>
            {toLines(text)}
          </WindupChildren>
        </div>
    </div>
  )
}

const toLines = (text: string) =>
  text.split('\n').map((line, index) =>
    line.trim() === '\\'
    ? <br key={index}/>
    : <div key={index} className="terminal-line">{line}</div>
    )
