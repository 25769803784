import React, { useEffect, useState } from "react";
import { Switch, Route, useHistory, BrowserRouter as Router, useLocation } from "react-router-dom";
import { Grid, Button } from "@material-ui/core";
import { ApolloProvider } from "../data/ApolloProvider";
import ReactGA from "react-ga";

const formRoutes = {
  home: "/",
  thankYou: "/thank-you"
}

export const Form = () => {
  const [likeClicked, setLikeClicked] = useState(false);
  const [answer, setAnswer] = useState(false);

  const clickLike = (answer: boolean) => {
    setLikeClicked(true);
    setAnswer(answer);
    answer ? ApolloProvider.sendLike() : ApolloProvider.sendDisLike();
  };

  return (
    /**
     * This router is included to force a change of the route when submitting the form.
     * It is needed to check conversions from analytics.
     */
    <Router>
      <div
        className="form"
        style={{
          backgroundImage: "url(backgroundLabel.png)",
          backgroundSize: "cover",
          marginTop: "100px",
        }}>
        <Grid container justify="center">
          <div>
            <p className="form-title">Did you like it?</p>
            <p className="form-description">
              (the framework, we know you loved the zombie)
          </p>
            <div
              className="formContianer"
              style={{ marginBottom: "40px", marginTop: "40px" }}>
              <Grid
                item
                container
                spacing={1}
                direction="row"
                alignItems="center"
                justify="center">
                <Grid item>
                  {(!likeClicked || answer) && (
                    <Button
                      className="formButton formButtonBlue"
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        clickLike(true);
                      }}>
                      Yes, this is awesome!
                    </Button>
                  )}
                </Grid>
                <Grid item>
                  {likeClicked && (
                    <Button
                      className="formButton"
                      variant="contained"
                      onClick={() => {
                        setLikeClicked(false);
                        setAnswer(false);
                      }}>
                      I've changed my mind!
                    </Button>
                  )}
                </Grid>
                <Grid item>
                  {(!likeClicked || !answer) && (
                    <Button
                      className="formButton"
                      variant="contained"
                      color="secondary"
                      onClick={() => {
                        clickLike(false);
                      }}>
                      Bah, it could be better
                    </Button>
                  )}
                </Grid>
              </Grid>
              {likeClicked && (
                <Grid item xs={12}>
                  {answer && (
                    <p className="form-text">
                      Yeah! Imagine what you can do with Booster in a zombie-free
                      world!
                    </p>
                  )}
                  {!answer && (
                    <p className="form-text">
                      Ok, you’re a tough one! We'd like to know why you are not
                      convinced.
                    </p>
                  )}
                  {answer && (
                    <p className="form-text">
                      Wanna know more? Leave your e-mail, and we’ll contact you.
                    </p>
                  )}
                  {!answer && (
                    <p className="form-text">
                      Give us feedback! Leave your e-mail, and we’ll contact you.
                    </p>
                  )}
                  <EmailInput />
                </Grid>
              )}
              <p className="form-title">
                Discover more about the project in{" "}
                <a href="https://framework.booster.cloud" style={{ color: "white" }}>
                  framework.booster.cloud
              </a>
              </p>
            </div>
          </div>
        </Grid>
      </div>
    </Router>
  );
};

const EmailInput = () => {
  const [email, setEmail] = useState("");
  let history = useHistory();

  const handleInput = (event: any) => {
    setEmail(event.target.value);
  };

  const sendAction = () => {
    if (email === "") {
      return;
    }

    ApolloProvider.sendEmail(email);
    ReactGA.pageview(formRoutes.thankYou);
    history.push(formRoutes.thankYou);
  };

  return (
    <Switch>
      <Route exact path={formRoutes.home}>
        <input
          type="text"
          className="emailInput"
          onChange={handleInput}
          placeholder="rick@grimes.com"/>
        <br />
        <Button
          className="formButton"
          variant="contained"
          color="primary"
          onClick={() => {
            sendAction();
          }}
          style={{ marginTop: "20px" }}>
          Send
        </Button>
      </Route>
      <Route exact path={formRoutes.thankYou}>
        <br />
        <p className="form-text">
          Thanks for your feedback!! we will contact you!
        </p>
      </Route>
    </Switch>
  )
}
