import { Grid } from "@material-ui/core";

export const Boom = () => (
  <Grid container className="animate__animated animate__lightSpeedInLeft">
    <Grid container style={{ width: "100%" }} justify="center">
      <p className="boom-title">...AND BOOM!</p>
    </Grid>
    <Grid container style={{ width: "100%" }} justify="center">
      <img
        style={{ width: "80%", height: "80%" }}
        alt="boom"
        src="https://media.giphy.com/media/YA6dmVW0gfIw8/giphy.gif"
      />
      <p className="boom-description">
        {" "}
        MESSAGE RECEIVED! We've blown that motherfucking bastard up!
      </p>
    </Grid>
  </Grid>
);
