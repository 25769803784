import { ApolloClient, InMemoryCache } from "@apollo/client";
import { gql } from "@apollo/client";
import Cookies from 'js-cookie';
import { v4 as uuid } from "uuid";

export class ApolloProvider {
  static readonly BACKEND_URL: string =
    "https://zbefgq2m4h.execute-api.eu-west-1.amazonaws.com/production/graphql";

  private static readonly sessionIDCookiesKey = "_session_id" 
  private static getUniqueID = () => {
    const id = Cookies.get(ApolloProvider.sessionIDCookiesKey) ?? uuid()
    Cookies.set(ApolloProvider.sessionIDCookiesKey, id)
    return id
  }
  
  private static readonly sessionID = ApolloProvider.getUniqueID()

  static sendEmail(email: string) {
    const SEND_EMAIL = gql`
      mutation SendEmail($sessionId: ID!, $email: String!) {
        SendEmail(input: { sessionId: $sessionId, email: $email })
      }
    `;
    ApolloProvider.dispatchGraphQL(SEND_EMAIL, {
      sessionId: ApolloProvider.sessionID,
      email: email,
    });
  }

  static sendLike() {
    const SEND_EMAIL = gql`
      mutation SendLike($sessionId: ID!) {
        SendLike(input: { sessionId: $sessionId })
      }
    `;
    ApolloProvider.dispatchGraphQL(SEND_EMAIL, {
      sessionId: ApolloProvider.sessionID,
    });
  }

  static sendDisLike() {
    const SEND_EMAIL = gql`
      mutation SendDislike($sessionId: ID!) {
        SendDislike(input: { sessionId: $sessionId })
      }
    `;
    ApolloProvider.dispatchGraphQL(SEND_EMAIL, {
      sessionId: ApolloProvider.sessionID,
    });
  }

  static trackVisit() {
    const SEND_EMAIL = gql`
      mutation TrackVisit($sessionId: ID!) {
        TrackVisit(input: { sessionId: $sessionId })
      }
    `;
    ApolloProvider.dispatchGraphQL(SEND_EMAIL, {
      sessionId: ApolloProvider.sessionID,
    });
  }

  private static dispatchGraphQL(operation: any, data: any) {
    const client = new ApolloClient({
      uri: this.BACKEND_URL,
      cache: new InMemoryCache(),
    });

    client
      .mutate({
        mutation: operation,
        variables: data,
      })
      .catch((err) => console.log(err));
  }
}
