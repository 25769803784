import { useEffect } from 'react'
import {ApolloProvider} from '../data/ApolloProvider'
import ReactGA from 'react-ga'



export const useBoosterAnalytics = () => {
  useEffect(() => {
    ReactGA.initialize('UA-190169478-1')
    ReactGA.pageview('/homepage')
    ApolloProvider.trackVisit()
  }, [])
}