import { Grid } from "@material-ui/core";
import React, { useEffect, useState, useContext } from "react";
import { addNewTimeLineItem } from "../context/actions";
import { DispatchContext } from "../context/context";
import { timeLineItems, TimeLineItem, isLast } from "../data/timeline";
import { Terminal } from "./Terminal";

const Separator = () => {
  return <div className="separator" />;
};

export const TimeLineGrid = () => {
  const [items, setItems] = useState<TimeLineItem[]>([]);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [triggerMoreItems, setLoadMoreItems] = useState<boolean>(false);

  const dispatch = useContext(DispatchContext);

  const loadMore = () => {
    if (!isLast(currentIndex)) {
      const newTimeLineItem = timeLineItems[currentIndex];
      dispatch(addNewTimeLineItem(currentIndex));
      setItems([...items, newTimeLineItem]);
      setCurrentIndex(currentIndex + 1);
      setLoadMoreItems(false);
    }
  };

  const scrollHandler = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop >=
      document.documentElement.offsetHeight
    ) {
      setLoadMoreItems(true);
    }
  };

  useEffect(() => {
    if (triggerMoreItems) {
      loadMore();
    }
  }, [triggerMoreItems]);

  useEffect(() => {
    window.scrollTo(0, 0);
    loadMore();
    window.addEventListener("scroll", scrollHandler);
    return () => window.removeEventListener("scroll", scrollHandler);
  }, []);

  const stepForIndex = (index: number) => {
    return `[${index + 1}/${timeLineItems.length}] `;
  };

  const renderItem = (item: TimeLineItem, index: number) => {
    return (
      <Grid
        key={index}
        className="card-item animate__animated animate__slideInLeft"
        item
        xs={12}
        sm={12}
      >
        <Separator />
        <p className="card-title">
          <span className="time">{stepForIndex(index)}</span>
          {item.title} - {item.time}
        </p>
        <p className="card-description">{item.description}</p>
        <Terminal key={index} item={item} />
      </Grid>
    );
  };

  const renderItems = () => {
    return items.map((item: TimeLineItem, index: number) => {
      return renderItem(item, index);
    });
  };

  return (
    <Grid container justify="center">
      {renderItems()}
    </Grid>
  );
};
