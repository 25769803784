import { Grid } from "@material-ui/core";

export const IntroText = () => (
  <Grid container>
    <Grid style={{ width: "100%" }}>
      <p className="intro-title">WHAT YOU’VE JUST SEEN IS REAL</p>
      <p className="intro-description">
        Find out how to build a serverless, scalable, and reliable app while
        being chased by a motherfucking zombie!
      </p>
    </Grid>
  </Grid>
);
