import Cookies from "js-cookie";
import React from "react";

const acceptanceKey = "_cookies_acceptance"

export const CookiesBanner = () => {
  const [showCookiesBanner, setShowCookiesBanner] = React.useState(Cookies.get(acceptanceKey) != "true")
  const closeCookieBanner = () => {
    Cookies.set(acceptanceKey, "true")
    setShowCookiesBanner(false)
  }

  return (
    showCookiesBanner ?
      <div id="cookies-container">
        <div className="scanline" />
        <p id="cookies-text">We use third-party cookies to improve our services and get anonymous statistical usage data of our website. If you continue browsing we consider that you accept its use. More information can be found in our
            <a className="cookies-link" href="https://www.booster.cloud/privacy-policy" target="_blank"> privacy policy</a> page.</p>
        <span onClick={closeCookieBanner} id="close-cookies-button">✕</span>
        <button onClick={closeCookieBanner} id="accept-cookies-button">I accept cookies</button>
      </div> :
      null
  )
}