import { Action, ActionType } from "./actions"
import { State } from "./state"

export const reducer = (
  state: State,
  action: Action
) => {
  switch (action.type) {
    case ActionType.RESET_TIME_LINE:
      return {
        ...state,
        currentIndex: 0
      }
    case ActionType.ADD_NEW_TIME_LINE_ITEM:
      return {
        ...state,
        currentIndex: action.payload,
      }
    default:
  }
  return state
}